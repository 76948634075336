/* .ant-modal-body {
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 16px !important;
} */
.main-modal {
    margin: 121px 114px;
    width: 478px;
    height: 180px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}
.modal-heading {
    color: #1A1A1A;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
}
.modal-text {
    color: #808080;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.age-verify-button {
    margin-left: 24px;
    display: flex;
    width: 149px;
    height: 48px;
    padding: 7px;
    justify-content: center;
    align-items: center;
    border-radius: 23px;
    background: var(--style-primary-color);
    color: #fff;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border: none;
  }

@media only screen and (max-width: 431px) {
    .modal {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 300px !important;
        height: 80px !important;
        border-radius: 16px !important;
    }
    .main-modal {
        margin: 70px 70px;
        width: 200px;
        height: 70px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
    .modal-heading {
        color: #1A1A1A;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
    }
    .modal-text {
        color: #808080;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    .age-verify-button {
        height: 34px;
        width: 100px;
        padding: 6px;
        font-size: 10px;
      }

}

.aboutUs-container{
    background-color: var(--style-about-us-background, #ffffff);
}

.aboutUs-heading{
    color: var(--style-about-us-heading-color, rgb(234, 30, 81)) !important;
    font-family: var(--style-about-us-heading-family, "Montserrat", sans-serif) !important;
    font-size: calc(var(--style-about-us-heading-size, 48) * 1px)!important;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;
    letter-spacing: -0.96px;
}

.aboutUs-text{
    display: flex;
    width: 637px;
    /* height: 143px; */
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--style-about-us-text-color, rgb(128, 128, 128));
    font-family: var(--style-secondary-font-family, "Montserrat", sans-serif);
    font-size: calc(var(--style-about-us-text-size, 18) * 1px);
}

.aboutUs-text * {
    color: var(--style-about-us-text-color, rgb(128, 128, 128));
    font-family: var(--style-secondary-font-family, "Poppins", sans-serif);
    font-size: calc(var(--style-about-us-text-size, 18) * 1px);
}

.div-sub-main-stat{
    padding: 16px;
    display: flex;
    width: 100%;
    box-shadow: 3px -2px 50px 0px rgba(63, 60, 61, 0.08);
    justify-content: space-around;
    background-color: var(--style-about-us-statistics-background-color, #ffffff);
}

.aboutUs-stat-text{
    color: var(--style-about-us-statistics-text-color, rgb(128, 128, 128));
    font-family: var(--style-secondary-font-family, "Poppins", sans-serif);
    font-size: calc(var(--style-about-us-statistics-text-size, 16) * 1px);
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.aboutUs-stat-number{
    color: var(--style-about-us-statistics-number-color, rgb(234, 30, 81));
    font-family: var(--style-primary-font-family, "Montserrat", sans-serif);
    font-size: calc(var(--style-about-us-statistics-number-size, 30) * 1px);
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
}

@media (max-width: 850px) {
    .home-page-arrow{
        display: none;
    }
}

.w-100{
    width: 100% !important;
}

.cursor-pointer{
    cursor: pointer !important;
}


.file-upload {
  margin-top: 15px;
  border: 1px solid #ccc;
  display: flex;
  padding: 7px 10px;
  cursor: pointer;
  border-radius: 25px;
  background-color: #fff;
  text-align: center;
  width: 100%; 
}

.file-upload input[type="file"] {
  display: none;
}

.file-type-label{
  background: #8080802b;
  padding: 5px 10px;
  border-radius: 7px;
}
.apple-pay-btn{
 width: 100%;
 height: 44px;
 border-radius: 32px;
 margin-top: 16px;
}
.apple-pay-btn-label{
    font-size: 18px;
    color: black;
}
.apple-pay-img{
    margin: 0px 8px;
}


/* Fixed WhatsApp Icon  */
.fixed-bottom-right {
    position: fixed;    /* Sticks the element to the bottom right of the screen */
    bottom: 20px;       /* Adjust the spacing from the bottom */
    right: 20px;        /* Adjust the spacing from the right */
    padding: 10px;      /* Add padding for spacing */
    z-index: 6;         /* Set the stacking order */
  }

  /* Ensure the img is responsive */
  .fixed-bottom-right img {
    width: 100px;        /* Size of the icon */
    height: auto;       /* Maintain aspect ratio */
  }

.promotion-price {
    text-decoration: line-through;
    color: black;
    font-size: 14px;
    margin-left: 5px;
    font-weight: 500;
    align-content:end
}
.promotion-qty {
    display: flex;
    justify-content: center;
}
.card-old-price-label {
    font-size: 20px;
    display: flex;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    color: var(--style-primary-color);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.72px;
  }
  
  .card-old-price-label-inactive {
    font-size: 20px;
    display: flex;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    color: var(--style-primary-color);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.72px;
  }
  
.list-product-item-in-detail-page:hover {
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--Gray-50, #F2F2F2);
    padding: 5px 10px;
    transition: 500ms;
}

.child-width-180PX{
    display: flex;
    justify-content: end;
}

.child-width-180PX >div {
    width:180px;
}


.scrolling-text {
  display: flex;
  align-items: center;
  height: 58px;
  flex-shrink: 0;
  background: var(--style-ticker-tape-background, #ffeb01);
}

.scrolling-text * {
  color: var(--style-ticker-tape-text-color, #333333);
  font-size: calc(var(--style-ticker-tape-text-size, 25) * 1px);
  /* Display Font/30px/Regular */
  font-family: var(--style-ticker-tape-font-family, "Montserrat", sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 126.667% */
}

@media (max-width: 430px) { 
  .scrolling-text {
    height: 48px !important;
  }

  .scrolling-text span {
    font-size: 17px;
  }
}

/*---- 18. Shop page  -------*/

.shop-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  @media #{$xs-layout} {
    display: block;
  }

  @media #{$sm-layout} {
    display: flex;
  }

  .select-shoing-wrap {
    display: flex;

    @media #{$xs-layout} {
      display: block;
    }

    @media #{$sm-layout} {
      display: flex;
    }

    .shop-select {
      margin-right: 50px;

      select {
        font-size: 14px;

        min-width: 143px;
        padding: 5px;

        color: #606060;
        border: 1px solid #e6e6e6;
      }
    }

    p {
      margin: 0;

      color: #606060;

      @media #{$xs-layout} {
        margin: 5px 0 10px;
      }

      @media #{$sm-layout} {
        margin: 0;
      }
    }
  }

  .shop-tab {

    a,
    button {
      font-size: 18px;

      margin-left: 15px;
      padding: 0;

      color: #606060;
      border: none;
      background: none;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        color: #fa6bff;
      }
    }
  }
}

.shop-list-wrap {
  .product-wrap {
    &:hover .hover-img {
      visibility: inherit;

      transform: none;

      opacity: inherit;
    }
  }

  .shop-list-content {
    @media #{$xs-layout} {
      margin-top: 20px;
    }

    @media #{$sm-layout} {
      margin-top: 0;
    }

    h3 {
      font-size: 24px;

      margin: 0;

      color: #010101;

      @media #{$xs-layout} {
        font-size: 20px;
        line-height: 20px;
      }

      a {
        color: #010101;

        &:hover {
          color: $theme-color;
        }
      }
    }

    .product-list-price {
      margin: 13px 0 22px;

      @media #{$xs-layout} {
        margin: 13px 0 13px;
      }

      @media #{$md-layout} {
        margin: 13px 0 19px;
      }

      span {
        font-size: 24px;
        line-height: 1;

        display: inline-block;

        color: #fe5252;

        &.old {
          font-size: 18px;

          margin-left: 15px;

          text-decoration: line-through;

          color: #cfcfcf;
        }
      }
    }

    .rating-review {
      display: flex;
      align-items: center;

      .product-list-rating {
        i {
          font-size: 17px;

          margin: 0 3px 0;

          color: #5f5d5d;

          &.yellow {
            color: #ffa900;
          }
        }
      }

      a {
        font-size: 15px;
        line-height: 1;

        position: relative;

        margin-left: 40px;

        color: #ffcf75;

        &:before {
          position: absolute;
          top: 0;
          left: -21px;

          width: 1px;
          height: 15px;

          content: "";

          background-color: #d1d1d1;
        }
      }
    }

    p {
      font-size: 15px;
      line-height: 28px;

      margin: 15px 0 40px;

      color: #8f8f8f;

      @media #{$xs-layout} {
        margin: 12px 0 20px;
      }

      @media #{$md-layout} {
        margin: 12px 0 20px;
      }

      @media #{$lg-layout} {
        margin: 15px 0 20px;
      }
    }

    .shop-list-btn {

      a,
      button {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;

        z-index: 1;

        display: inline-block;

        padding: 14px 32px 15px;

        text-transform: uppercase;

        color: #fff;
        border: 1px solid transparent;
        background-color: #343538;

        @media #{$xs-layout} {
          font-size: 13px;

          padding: 10px 20px 11px;
        }

        &:hover {
          border: 1px solid $theme-color;
        }
      }
    }
  }

  &:hover .product-wrap .hover-img {
    visibility: visible;

    transform: translate(-50%, 0);

    opacity: 1;
  }
}

.pro-pagination-style {
  @media #{$xs-layout} {
    &.mt-30 {
      margin-top: 10px;
    }
  }

  ul {
    li {
      display: inline-block;

      margin: 0 4px;

      a,
      button {
        font-size: 16px;
        line-height: 43px;

        display: inline-block;

        width: 43px;
        height: 43px;
        padding: 0;

        text-align: center;

        color: $theme-color;
        border: none;
        border-radius: 100% !important;
        background: none;
        box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1);

        &:hover {
          color: #fff;
          background-color: $theme-color;
        }
      }

      a.active {
        color: #fff;
        background-color: $theme-color;
        box-shadow: none;

        &:hover {
          background-color: #333;
        }
      }

      a.prev,
      a.next {
        font-size: 17px;

        color: $theme-color;
        background-color: #f6f6f6;
        box-shadow: none;

        &:hover {
          color: #fff;
          background-color: $theme-color;
        }
      }

      &.page-item {
        &.active {
          .page-link {
            border-color: $theme-color;
            background-color: $theme-color;
            box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;

            &:focus {
              box-shadow: none;
            }
          }
        }

        .page-link {
          box-shadow: 0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@media #{$xs-layout} {
  .shop-area.pb-100 {
    padding-bottom: 45px;
  }
}

.list2-col-style {
  .shop-list-wrap {
    .shop-list-content {
      @media #{$xl-layout} {
        h3 {
          font-size: 20px;
          line-height: 20px;
        }

        .product-list-price {
          margin: 13px 0 10px;
        }

        p {
          margin: 6px 0 20px;
        }

        .shop-list-btn {
          a {
            font-size: 13px;

            padding: 10px 24px 11px;
          }
        }
      }

      @media #{$lg-layout} {
        h3 {
          font-size: 20px;
          line-height: 20px;
        }

        .product-list-price {
          margin: 13px 0 10px;
        }

        p {
          margin: 6px 0 20px;
        }

        .shop-list-btn {
          a {
            font-size: 13px;

            padding: 10px 20px 11px;
          }
        }
      }
    }
  }
}

.grid-sizer {
  width: 25%;
}

.shop-bottom-area {
  .col-xl-4 {
    transition: 0.5s;
  }

  .grid {
    .shop-list-wrap {
      display: none;
    }

    &.two-column {
      .col-xl-4 {
        flex: 1 0 50%;

        max-width: 50%;

        transition: 0.5s;
      }
    }
  }

  .list {
    .product-wrap {
      display: none;
    }

    &.two-column {
      &>.col-xl-4 {
        flex: 1 0 50%;

        max-width: 50%;

        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }

        @media #{$md-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }

        @media #{$xs-layout} {
          flex: 1 0 100%;

          max-width: 100%;
        }
      }
    }

    &>.col-xl-4 {
      flex: 1 0 100%;

      max-width: 100%;

      transition: 0.5s;
    }
  }
}

.shop-list-wishlist,
.shop-list-compare {
  button {
    font-size: 20px;

    padding: 0;

    border: none;
    background: none;

    &:hover,
    &.active {
      color: $theme-color;
    }
  }
}

.shop-area {
  .container-fluid {
    padding: 0 40px;

    @media #{$xx-layout} {
      padding: 0 15px;
    }

    @media #{$xl-layout} {
      padding: 0 15px;
    }

    @media #{$lg-layout} {
      padding: 0 30px;
    }

    @media #{$md-layout} {
      padding: 0 40px;
    }

    @media #{$xs-layout} {
      padding: 0 15px;
    }
  }
}

.ant-modal-wrap {
  z-index: 1051 !important;
}

div.ant-select-selection-search {
  input {
    height: 37px !important;
    padding: 3px;
  }
}

.navbar {
  width: 100vw;
  position: fixed;
  z-index: 10;
  background-color: var(--style-nav-background, #ffffff);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
}

.extra-row {
  height: 100px
}

.sidebar {
  border-radius: 24px;
  // background: #FFF;
  // box-shadow: 0px 24px 48px -12px rgba(236, 26, 82, 0.05), 0px -24px 48px 0px rgba(0, 0, 0, 0.05);
}

.sidebar-widget {
  margin-bottom: 25px;
}

.ant-tree-list-holder-inner .ant-tree-treenode span {
  font-weight: 500;
  font-size: 16px;
}

.brand-search-sidebar-bar {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 27px;
  border: 1px solid;
  border-color: #E6E6E6;
  // margin-right: 10px;
  background-color: #fff;
  padding-left: 15px;
  margin-top: 16px;
  margin-bottom: 13px;
}
.brand-search {
  width: 308px;
  height: 40px;
  border-radius: 27px;
  background-color: #fff;
  border: none;
}
.brand-search-sidebar-bar .search-icon {
  position: absolute;
  font-size: 20px;
  color: #ccc;
}
.scroller-class {
  max-height: 904px;
  overflow-y: auto;
  // padding-right: 10px;
}

.scroller-class::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

.scroller-class::-webkit-scrollbar-track {
  background: #f7f7f7;
  /* Background of the scrollbar track */
  border: 1px solid #b3b3b3;
  /* background-color: #F2F2F2; */
  border-radius: 50px;
}

.scroller-class::-webkit-scrollbar-thumb {
  border-radius: 100px;
  height: 150px;
  background: #7a7a7a;
  /* border:1px solid #B3B3B3;Creates padding around the scrollbar thumb */
}

// 
.sidebar .pro-sidebar-search-form {
  height: 45px;
}

.sidebar .pro-sidebar-search-form input {
  height: 40px !important;
  padding-left: 16px;
  border-radius: 27px;
  background: #fff;
  border: 1px solid;
  border-color: #E6E6E6;
  // border: none
}

.sidebar .pro-sidebar-search-form input::placeholder {
  color: var(--gray-500, #808080);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}


.pro-sidebar-search-form .search-icon {
  position: absolute;
  top: 46%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #ccc;
}

// categories
.pro-sidebar-title {
  color: var(--primary-900, #EA1E51);
  font-family: "Montserrat";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.category-label img {
  width: 17.72px;
  height: 14.32px;
  cursor: pointer
}

.margin-top-min-15 {
  margin-top: -15px;
}
.category-description {
  padding: 0px 30px 0px 30px;
  margin-top:10%;
}
.category-description-heading {
  color: var(--primary-900);
  font-family: var(--display-font-24px-regular-font-family) !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  letter-spacing: var(--display-font-16px-regular-letter-spacing);
  line-height: var(--display-font-16px-regular-line-height);
  margin-top: 5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  margin-bottom: 25px;
}
.search-brands {
  display: flex;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background: var(--primary-50, #FDE8EE);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
}

.search-brands div {
  color: var(--primary-900, #EA1E51);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 112.5% */
}

.cart-icon-logo,
.profile-header-logo,
.wishlist-icon-logo,
.compare-icon-logo {
  margin-left: 11px;
}

.nav-search .ant-input-group-addon:first-child {
  border-radius: 22px 0px 0px 22px;
  border: 1px solid #F2F2F2;
}

.nav-search input.ant-input {
  height: 48px !important;
  background: #F6F6F6;
  border: none;
  border-radius: 0px 22px 22px 0px;
}

.nav-search input:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.nav-search input::placeholder {
  color: #808080;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.nav-search .ant-input-group-addon:last-child {
  width: 119px;
  height: 48px;
  border-radius: 0px 22px 22px 0px;
  border: 1px solid var(--gray-50, #F2F2F2);
  background: #F6F6F6;
}

.navFooter {
  margin-right: 50px;
}


div.header-right-wrap span.ant-input-group-wrapper {
  width: 305px;
  height: 45px
}

.breadcrumb-content {
  margin-left: 70px;
}


.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
}

.cursor-pointer {
  cursor: pointer;
}

.unit-select .ant-select-selector {
  display: flex !important;
  width: 105px !important;
  height: 32px !important;
  padding: 10px 16px !important;
  justify-content: space-between !important;
  align-items: center !important;
  color: #010101 !important;
  border-radius: 32px !important;
  border: none !important;
  background: #F7F7F7 !important;
}

.unit-select .ant-select-arrow {
  color: #010101 !important;
}

.qty-select .ant-select-selector {
  display: flex !important;
  width: 152px !important;
  height: 32px !important;
  padding: 10px 16px !important;
  justify-content: space-between !important;
  align-items: center !important;

  border-radius: 32px !important;
  border: 1px solid #999 !important;
  background: #FFF !important;
}

.qty-select .ant-select-selector .ant-select-selection-overflow {
  margin-top: -17px
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

.wishlist-logo {
  position: absolute;
  padding-right: 20px;
  display: flex;
  justify-content: right;
  width: 100%;
  z-index: 1;

  img {
    cursor: pointer;
  }
}
@media screen and (max-width: 850px) {
.category-name {
  padding: 2px;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding: 0px;
}
.scroller-class {
  display: none;
}
.pro-sidebar-search .pro-sidebar-search-form input {
  border-radius: 27px;
}
}
@media screen and (max-width: 430px) {
  .wishlist-logo {
    width: auto;

    img {
      height: 40px;
      width: 40px
    }
  }

  .extra-row {
    height: 110px
  }

  .brand-search-sidebar-bar .search-icon {
    position: relative;
    right:-22px !important
  }
  .brand-search {
    width: 262px;
  }
  
}

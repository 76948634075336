.banner-tmpl,
.temp-banner{
  background-color: var(--style-slider-background, #ffffff);

  .ant-carousel,
  .slick-track {
    @apply tw-h-full;
  }

  .slick-slider {
    @apply tw-h-full;

    .slick-list{
      @apply tw-h-full;
    }
    .slick-slide {
      @apply tw-h-full;

      > div,
      .slider-content{
        height: 100%
      }
      .slider-content{
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
          position: absolute;
        }
      }
    }
  }
}

.banner-tmpl{ 
  padding-left: calc(var(--style-banner-gap, 1rem) * 1px);
  padding-bottom: calc(var(--style-banner-gap, 1rem) * 1px);

  .aspect-ratio {
    @apply tw-h-0 tw-relative;
    
    

    img{
      @apply tw-w-full tw-h-full;
    }
  }
  @media (max-width: 768px) {
    .aspect-ratio-content{
      @apply tw-flex-col;
    }
  }
}
.aspect-ratio-content {
  padding: calc(var(--style-banner-gap, 1rem) * 1px);
  padding-left: 0;
  padding-bottom: 0;

  @apply
    tw-overflow-hidden
    tw-h-full
    tw-w-full
    tw-top-0
    tw-left-0
    tw-absolute;
}

.banner-tmpl-0 {
  .wide-aspect {
    padding-top: 51.4%;

  }
  .small-aspect {
    padding-top: 77.1%
  }

  @media (max-width: 768px) {
    .small-aspect {
      padding-top: 38.5%;
      width: 50%;
    }
  }
}

.banner-tmpl-1 {
  .wide-aspect {
    padding-top: 37.6%;
  }
}

.banner-tmpl-2 {
  .tall-aspect {
    padding-top: 122.3%;
  }
  .small-aspect {
    padding-top: 61.1%;
  }

  @media (max-width: 768px) {
    .small-aspect {
      padding-top: 30.5%;
      width: 50%;
    }
  }
}

.banner-tmpl-3 {
  .wide-aspect {
    padding-top: 65.7%;
  }
  .tall-aspect {
    padding-top: 180.5%;
  }
  .small-aspect {
    padding-top: 72.3%;
  }

  @media (max-width: 768px) {
    .small-aspect {
      padding-top: 36.2%;
      width: 50%;
    }
  }
}

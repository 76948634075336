:root {
  --black: rgba(0, 0, 0, 1);
  --display-font-12px-bold-font-family: "Montserrat-Bold", Helvetica;
  --display-font-12px-bold-font-size: 12px;
  --display-font-12px-bold-font-style: normal;
  --display-font-12px-bold-font-weight: 700;
  --display-font-12px-bold-letter-spacing: 0px;
  --display-font-12px-bold-line-height: 18px;
  --display-font-12px-medium-font-family: "Montserrat-Medium", Helvetica;
  --display-font-12px-medium-font-size: 12px;
  --display-font-12px-medium-font-style: normal;
  --display-font-12px-medium-font-weight: 500;
  --display-font-12px-medium-letter-spacing: 0px;
  --display-font-12px-medium-line-height: 18px;
  --display-font-12px-regular-font-family: "Montserrat-Regular", Helvetica;
  --display-font-12px-regular-font-size: 12px;
  --display-font-12px-regular-font-style: normal;
  --display-font-12px-regular-font-weight: 400;
  --display-font-12px-regular-letter-spacing: 0px;
  --display-font-12px-regular-line-height: 18px;
  --display-font-12px-samibold-font-family: "Montserrat-SemiBold", Helvetica;
  --display-font-12px-samibold-font-size: 12px;
  --display-font-12px-samibold-font-style: normal;
  --display-font-12px-samibold-font-weight: 600;
  --display-font-12px-samibold-letter-spacing: 0px;
  --display-font-12px-samibold-line-height: 18px;
  --display-font-14px-bold-font-family: "Montserrat-Bold", Helvetica;
  --display-font-14px-bold-font-size: 14px;
  --display-font-14px-bold-font-style: normal;
  --display-font-14px-bold-font-weight: 700;
  --display-font-14px-bold-letter-spacing: 0px;
  --display-font-14px-bold-line-height: 20px;
  --display-font-14px-medium-font-family: "Montserrat-Medium", Helvetica;
  --display-font-14px-medium-font-size: 14px;
  --display-font-14px-medium-font-style: normal;
  --display-font-14px-medium-font-weight: 500;
  --display-font-14px-medium-letter-spacing: 0px;
  --display-font-14px-medium-line-height: 20px;
  --display-font-14px-regular-font-family: "Montserrat-Regular", Helvetica;
  --display-font-14px-regular-font-size: 14px;
  --display-font-14px-regular-font-style: normal;
  --display-font-14px-regular-font-weight: 400;
  --display-font-14px-regular-letter-spacing: 0px;
  --display-font-14px-regular-line-height: 20px;
  --display-font-14px-samibold-font-family: "Montserrat-SemiBold", Helvetica;
  --display-font-14px-samibold-font-size: 14px;
  --display-font-14px-samibold-font-style: normal;
  --display-font-14px-samibold-font-weight: 600;
  --display-font-14px-samibold-letter-spacing: 0px;
  --display-font-14px-samibold-line-height: 20px;
  --display-font-16px-bold-font-family: "Montserrat-Bold", Helvetica;
  --display-font-16px-bold-font-size: 16px;
  --display-font-16px-bold-font-style: normal;
  --display-font-16px-bold-font-weight: 700;
  --display-font-16px-bold-letter-spacing: 0px;
  --display-font-16px-bold-line-height: 24px;
  --display-font-16px-medium-font-family: "Montserrat-Medium", Helvetica;
  --display-font-16px-medium-font-size: 16px;
  --display-font-16px-medium-font-style: normal;
  --display-font-16px-medium-font-weight: 500;
  --display-font-16px-medium-letter-spacing: 0px;
  --display-font-16px-medium-line-height: 24px;
  --display-font-16px-regular-font-family: "Montserrat-Regular", Helvetica;
  --display-font-16px-regular-font-size: 16px;
  --display-font-16px-regular-font-style: normal;
  --display-font-16px-regular-font-weight: 400;
  --display-font-16px-regular-letter-spacing: 0px;
  --display-font-16px-regular-line-height: 24px;
  --display-font-16px-samibold-font-family: "Montserrat-SemiBold", Helvetica;
  --display-font-16px-samibold-font-size: 16px;
  --display-font-16px-samibold-font-style: normal;
  --display-font-16px-samibold-font-weight: 600;
  --display-font-16px-samibold-letter-spacing: 0px;
  --display-font-16px-samibold-line-height: 24px;
  --display-font-18px-bold-font-family: "Montserrat-Bold", Helvetica;
  --display-font-18px-bold-font-size: 18px;
  --display-font-18px-bold-font-style: normal;
  --display-font-18px-bold-font-weight: 700;
  --display-font-18px-bold-letter-spacing: 0px;
  --display-font-18px-bold-line-height: 28px;
  --display-font-18px-medium-font-family: "Montserrat-Medium", Helvetica;
  --display-font-18px-medium-font-size: 18px;
  --display-font-18px-medium-font-style: normal;
  --display-font-18px-medium-font-weight: 500;
  --display-font-18px-medium-letter-spacing: 0px;
  --display-font-18px-medium-line-height: 28px;
  --display-font-18px-regular-font-family: "Montserrat-Regular", Helvetica;
  --display-font-18px-regular-font-size: 18px;
  --display-font-18px-regular-font-style: normal;
  --display-font-18px-regular-font-weight: 400;
  --display-font-18px-regular-letter-spacing: 0px;
  --display-font-18px-regular-line-height: 28px;
  --display-font-18px-samibold-font-family: "Montserrat-SemiBold", Helvetica;
  --display-font-18px-samibold-font-size: 18px;
  --display-font-18px-samibold-font-style: normal;
  --display-font-18px-samibold-font-weight: 600;
  --display-font-18px-samibold-letter-spacing: 0px;
  --display-font-18px-samibold-line-height: 28px;
  --display-font-20px-bold-font-family: "Montserrat-Bold", Helvetica;
  --display-font-20px-bold-font-size: 20px;
  --display-font-20px-bold-font-style: normal;
  --display-font-20px-bold-font-weight: 700;
  --display-font-20px-bold-letter-spacing: 0px;
  --display-font-20px-bold-line-height: 30px;
  --display-font-20px-medium-font-family: "Montserrat-Medium", Helvetica;
  --display-font-20px-medium-font-size: 20px;
  --display-font-20px-medium-font-style: normal;
  --display-font-20px-medium-font-weight: 500;
  --display-font-20px-medium-letter-spacing: 0px;
  --display-font-20px-medium-line-height: 30px;
  --display-font-20px-regular-font-family: "Montserrat-Regular", Helvetica;
  --display-font-20px-regular-font-size: 20px;
  --display-font-20px-regular-font-style: normal;
  --display-font-20px-regular-font-weight: 400;
  --display-font-20px-regular-letter-spacing: 0px;
  --display-font-20px-regular-line-height: 30px;
  --display-font-20px-samibold-font-family: "Montserrat-SemiBold", Helvetica;
  --display-font-20px-samibold-font-size: 20px;
  --display-font-20px-samibold-font-style: normal;
  --display-font-20px-samibold-font-weight: 600;
  --display-font-20px-samibold-letter-spacing: 0px;
  --display-font-20px-samibold-line-height: 30px;
  --display-font-24px-bold-font-family: "Montserrat-Bold", Helvetica;
  --display-font-24px-bold-font-size: 24px;
  --display-font-24px-bold-font-style: normal;
  --display-font-24px-bold-font-weight: 700;
  --display-font-24px-bold-letter-spacing: 0px;
  --display-font-24px-bold-line-height: 32px;
  --display-font-24px-medium-font-family: "Montserrat-Medium", Helvetica;
  --display-font-24px-medium-font-size: 24px;
  --display-font-24px-medium-font-style: normal;
  --display-font-24px-medium-font-weight: 500;
  --display-font-24px-medium-letter-spacing: 0px;
  --display-font-24px-medium-line-height: 32px;
  --display-font-24px-regular-font-family: "Montserrat-Regular", Helvetica;
  --display-font-24px-regular-font-size: 24px;
  --display-font-24px-regular-font-style: normal;
  --display-font-24px-regular-font-weight: 400;
  --display-font-24px-regular-letter-spacing: 0px;
  --display-font-24px-regular-line-height: 32px;
  --display-font-24px-samibold-font-family: "Montserrat-SemiBold", Helvetica;
  --display-font-24px-samibold-font-size: 24px;
  --display-font-24px-samibold-font-style: normal;
  --display-font-24px-samibold-font-weight: 600;
  --display-font-24px-samibold-letter-spacing: 0px;
  --display-font-24px-samibold-line-height: 32px;
  --display-font-30px-bold-font-family: "Montserrat-Bold", Helvetica;
  --display-font-30px-bold-font-size: 30px;
  --display-font-30px-bold-font-style: normal;
  --display-font-30px-bold-font-weight: 700;
  --display-font-30px-bold-letter-spacing: 0px;
  --display-font-30px-bold-line-height: 38px;
  --display-font-30px-medium-font-family: "Montserrat-Medium", Helvetica;
  --display-font-30px-medium-font-size: 30px;
  --display-font-30px-medium-font-style: normal;
  --display-font-30px-medium-font-weight: 500;
  --display-font-30px-medium-letter-spacing: 0px;
  --display-font-30px-medium-line-height: 38px;
  --display-font-30px-regular-font-family: "Montserrat-Regular", Helvetica;
  --display-font-30px-regular-font-size: 30px;
  --display-font-30px-regular-font-style: normal;
  --display-font-30px-regular-font-weight: 400;
  --display-font-30px-regular-letter-spacing: 0px;
  --display-font-30px-regular-line-height: 38px;
  --display-font-30px-samibold-font-family: "Montserrat-SemiBold", Helvetica;
  --display-font-30px-samibold-font-size: 30px;
  --display-font-30px-samibold-font-style: normal;
  --display-font-30px-samibold-font-weight: 600;
  --display-font-30px-samibold-letter-spacing: 0px;
  --display-font-30px-samibold-line-height: 38px;
  --display-font-36px-bold-font-family: "Montserrat-Bold", Helvetica;
  --display-font-36px-bold-font-size: 36px;
  --display-font-36px-bold-font-style: normal;
  --display-font-36px-bold-font-weight: 700;
  --display-font-36px-bold-letter-spacing: -0.72px;
  --display-font-36px-bold-line-height: 44px;
  --display-font-36px-medium-font-family: "Montserrat-Medium", Helvetica;
  --display-font-36px-medium-font-size: 36px;
  --display-font-36px-medium-font-style: normal;
  --display-font-36px-medium-font-weight: 500;
  --display-font-36px-medium-letter-spacing: -0.72px;
  --display-font-36px-medium-line-height: 44px;
  --display-font-36px-regular-font-family: "Montserrat-Regular", Helvetica;
  --display-font-36px-regular-font-size: 36px;
  --display-font-36px-regular-font-style: normal;
  --display-font-36px-regular-font-weight: 400;
  --display-font-36px-regular-letter-spacing: -0.72px;
  --display-font-36px-regular-line-height: 44px;
  --display-font-36px-samibold-font-family: "Montserrat-SemiBold", Helvetica;
  --display-font-36px-samibold-font-size: 36px;
  --display-font-36px-samibold-font-style: normal;
  --display-font-36px-samibold-font-weight: 600;
  --display-font-36px-samibold-letter-spacing: -0.72px;
  --display-font-36px-samibold-line-height: 44px;
  --display-font-48px-bold-font-family: "Montserrat-Bold", Helvetica;
  --display-font-48px-bold-font-size: 48px;
  --display-font-48px-bold-font-style: normal;
  --display-font-48px-bold-font-weight: 700;
  --display-font-48px-bold-letter-spacing: -0.96px;
  --display-font-48px-bold-line-height: 60px;
  --display-font-48px-medium-font-family: "Montserrat-Medium", Helvetica;
  --display-font-48px-medium-font-size: 48px;
  --display-font-48px-medium-font-style: normal;
  --display-font-48px-medium-font-weight: 500;
  --display-font-48px-medium-letter-spacing: -0.96px;
  --display-font-48px-medium-line-height: 60px;
  --display-font-48px-regular-font-family: "Montserrat-Regular", Helvetica;
  --display-font-48px-regular-font-size: 48px;
  --display-font-48px-regular-font-style: normal;
  --display-font-48px-regular-font-weight: 400;
  --display-font-48px-regular-letter-spacing: -0.96px;
  --display-font-48px-regular-line-height: 60px;
  --display-font-48px-samibold-font-family: "Montserrat-SemiBold", Helvetica;
  --display-font-48px-samibold-font-size: 48px;
  --display-font-48px-samibold-font-style: normal;
  --display-font-48px-samibold-font-weight: 600;
  --display-font-48px-samibold-letter-spacing: -0.96px;
  --display-font-48px-samibold-line-height: 60px;
  --display-font-60px-bold-font-family: "Montserrat-Bold", Helvetica;
  --display-font-60px-bold-font-size: 60px;
  --display-font-60px-bold-font-style: normal;
  --display-font-60px-bold-font-weight: 700;
  --display-font-60px-bold-letter-spacing: -1.2px;
  --display-font-60px-bold-line-height: 72px;
  --display-font-60px-medium-font-family: "Montserrat-Medium", Helvetica;
  --display-font-60px-medium-font-size: 60px;
  --display-font-60px-medium-font-style: normal;
  --display-font-60px-medium-font-weight: 500;
  --display-font-60px-medium-letter-spacing: -1.2px;
  --display-font-60px-medium-line-height: 72px;
  --display-font-60px-regular-font-family: "Montserrat-Regular", Helvetica;
  --display-font-60px-regular-font-size: 60px;
  --display-font-60px-regular-font-style: normal;
  --display-font-60px-regular-font-weight: 400;
  --display-font-60px-regular-letter-spacing: -1.2px;
  --display-font-60px-regular-line-height: 72px;
  --display-font-60px-samibold-font-family: "Montserrat-SemiBold", Helvetica;
  --display-font-60px-samibold-font-size: 60px;
  --display-font-60px-samibold-font-style: normal;
  --display-font-60px-samibold-font-weight: 600;
  --display-font-60px-samibold-letter-spacing: -1.2px;
  --display-font-60px-samibold-line-height: 72px;
  --display-font-72px-bold-font-family: "Montserrat-Bold", Helvetica;
  --display-font-72px-bold-font-size: 72px;
  --display-font-72px-bold-font-style: normal;
  --display-font-72px-bold-font-weight: 700;
  --display-font-72px-bold-letter-spacing: -1.44px;
  --display-font-72px-bold-line-height: 90px;
  --display-font-72px-medium-font-family: "Montserrat-Medium", Helvetica;
  --display-font-72px-medium-font-size: 72px;
  --display-font-72px-medium-font-style: normal;
  --display-font-72px-medium-font-weight: 500;
  --display-font-72px-medium-letter-spacing: -1.44px;
  --display-font-72px-medium-line-height: 90px;
  --display-font-72px-regular-font-family: "Montserrat-Regular", Helvetica;
  --display-font-72px-regular-font-size: 72px;
  --display-font-72px-regular-font-style: normal;
  --display-font-72px-regular-font-weight: 400;
  --display-font-72px-regular-letter-spacing: -1.44px;
  --display-font-72px-regular-line-height: 90px;
  --display-font-72px-samibold-font-family: "Montserrat-SemiBold", Helvetica;
  --display-font-72px-samibold-font-size: 72px;
  --display-font-72px-samibold-font-style: normal;
  --display-font-72px-samibold-font-weight: 600;
  --display-font-72px-samibold-letter-spacing: -1.44px;
  --display-font-72px-samibold-line-height: 90px;
  --drop-shadow: 0px 24px 48px -12px rgba(236, 26, 82, 0.05);
  --erorr-100: rgba(254, 207, 204, 1);
  --erorr-200: rgba(254, 184, 178, 1);
  --erorr-300: rgba(254, 178, 172, 1);
  --erorr-400: rgba(253, 136, 128, 1);
  --erorr-50: rgba(255, 231, 229, 1);
  --erorr-500: rgba(253, 112, 102, 1);
  --erorr-600: rgba(253, 88, 76, 1);
  --erorr-700: rgba(252, 64, 51, 1);
  --erorr-800: rgba(252, 41, 25, 1);
  --erorr-900: rgba(251, 17, 0, 1);
  --erorrhover: rgba(192, 11, 56, 1);
  --gray-100: rgba(230, 230, 230, 1);
  --gray-200: rgba(204, 204, 204, 1);
  --gray-300: rgba(179, 179, 179, 1);
  --gray-400: rgba(153, 153, 153, 1);
  --gray-50: rgba(242, 242, 242, 1);
  --gray-500: rgba(128, 128, 128, 1);
  --gray-600: rgba(102, 102, 102, 1);
  --gray-700: rgba(76, 76, 76, 1);
  --gray-800: rgba(51, 51, 51, 1);
  --gray-900: rgba(26, 26, 26, 1);
  --info-100: rgba(205, 218, 251, 1);
  --info-200: rgba(180, 199, 249, 1);
  --info-300: rgba(155, 181, 246, 1);
  --info-400: rgba(130, 162, 244, 1);
  --info-50: rgba(230, 236, 253, 1);
  --info-500: rgba(106, 144, 242, 1);
  --info-600: rgba(81, 125, 240, 1);
  --info-700: rgba(56, 107, 238, 1);
  --info-800: rgba(31, 88, 236, 1);
  --info-900: rgba(6, 70, 233, 1);
  --infohover: rgba(44, 24, 131, 1);
  --primary-100: rgba(251, 210, 220, 1);
  --primary-200: rgba(249, 187, 203, 1);
  --primary-300: rgba(247, 165, 185, 1);
  --primary-400: rgba(244, 142, 168, 1);
  --primary-50: rgba(253, 232, 238, 1);
  --primary-500: rgba(242, 120, 151, 1);
  --primary-600: rgba(240, 97, 133, 1);
  --primary-700: rgba(238, 75, 116, 1);
  --primary-800: rgba(236, 52, 98, 1);
  --primary-900: rgba(234, 30, 81, 1);
  --primaryhover: rgba(192, 11, 56, 1);
  --secondary-100: rgba(223, 219, 240, 1);
  --secondary-200: rgba(207, 201, 233, 1);
  --secondary-300: rgba(191, 183, 226, 1);
  --secondary-400: rgba(175, 165, 218, 1);
  --secondary-50: rgba(239, 237, 248, 1);
  --secondary-500: rgba(159, 147, 211, 1);
  --secondary-600: rgba(143, 129, 204, 1);
  --secondary-700: rgba(127, 111, 197, 1);
  --secondary-800: rgba(111, 93, 189, 1);
  --secondary-900: rgba(95, 75, 182, 1);
  --secondaryhover: rgba(44, 24, 131, 1);
  --success-100: rgba(213, 234, 225, 1);
  --success-200: rgba(193, 223, 210, 1);
  --success-300: rgba(172, 212, 195, 1);
  --success-400: rgba(151, 201, 180, 1);
  --success-50: rgba(234, 244, 240, 1);
  --success-500: rgba(130, 191, 165, 1);
  --success-600: rgba(109, 180, 150, 1);
  --success-700: rgba(89, 169, 135, 1);
  --success-800: rgba(68, 159, 120, 1);
  --success-900: rgba(47, 148, 105, 1);
  --successhover: rgba(0, 97, 54, 1);
  --warning-100: rgba(252, 246, 215, 1);
  --warning-200: rgba(250, 241, 195, 1);
  --warning-300: rgba(248, 237, 175, 1);
  --warning-400: rgba(247, 232, 155, 1);
  --warning-50: rgba(253, 250, 235, 1);
  --warning-500: rgba(245, 228, 135, 1);
  --warning-600: rgba(244, 223, 115, 1);
  --warning-700: rgba(242, 219, 96, 1);
  --warning-800: rgba(240, 214, 76, 1);
  --warning-900: rgba(239, 209, 56, 1);
  --warninghover: rgba(213, 184, 30, 1);
  --white: rgba(255, 255, 255, 1);
}

* {
  font-family: Montserrat;
}

.mobile-view {
  display: none;
}

.footer {
  background-color: var(--style-footer-background-color, rgba(26, 26, 26, 1));
  width: 100%
}

.footer .row-one {
  display: flex;
  justify-content: space-between;
  padding: 70px 70px 45px 70px;
}

.footer .column-one {
  display: flex;
  flex-direction: column;
  width: 330px;
}

.column-one .first-half {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.first-half .logo-footer {
  width: 206px;
  height: 70px;
}

.first-half .discription-footer p{
  color: var(--style-footer-text-color, #B3B3B3);
  font-size: calc(var(--style-footer-text-size, 16) * 1px);
  font-family: var(--style-secondary-font-family, "Poppins", sans-serif);
  width: 370px;
  max-width: 98%;
  margin-top: 30px;
  margin-bottom: 40px;
}
.disclaimer-footer p{
  color: var(--style-footer-text-color, #B3B3B3);
  font-family: var(--style-secondary-font-family, "Poppins", sans-serif);
  width: 98%;
  max-width: 98%;
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
  padding: 20px 50px 14px 50px;
}

.first-half .social-icon {
  width: 224px;
  height: 32px;
}

.footer .column-two {
  width: 227px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.footer .headings {
  color: var(--style-footer-titles-text-color, rgba(26, 26, 26, 1));
  font-size: calc(var(--style-footer-titles-text-size, 16) * 1px);
  font-family: var(--style-primary-font-family, "Montserrat", sans-serif);
}

.footer .content {
  color: var(--style-footer-text-color, #B3B3B3);
  font-size: calc(var(--style-footer-text-size, 16) * 1px);
  font-family: var(--style-secondary-font-family, "Poppins", sans-serif);
}

.footer .column-three {
  display: flex;
  flex-direction: column;
  width: 228px;
  gap: 20px;
}
.display-none{
  display: none !important;
}

.footer .column-four {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.column-four .download {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.column-four .play-store {
  width: 228px;
  height: 68px;
}

.copyrights {
  padding: 20px 50px 10px 50px
}

.copyrights .border {
  border-top: 1px solid;
  border-color: white;
  margin-bottom: 20px;
}

.copyrights .first-half-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyrights .content-one,
.copyrights .content-one p {
  color: var(--style-footer-text-color, #B3B3B3);
  font-size: calc(var(--style-footer-text-size, 16) * 1px);
  font-family: var(--style-secondary-font-family, "Poppins", sans-serif);
}

.copyrights .content-one p {
  display: flex;
  flex-direction: row;
}

.copyrights .twenty-plus {
  width: 60px;
  height: 58px;
}

.row-one .columns {
  display: flex;
  flex-direction: row;
  width: 1160px;
  justify-content: space-around;
}

@media (max-width: 850px) {
  .mobile-view {
    display: none;
  }

  .row-one{
    flex-direction: column !important;
    gap: 12px;
  }
  .first-half{
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }

  .footer .row-one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 45px 45px 5px 45px;
  }

  .footer .column-one {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    align-content: center;
    width: 100% !important;
  }

  .first-half .logo-footer {
    width: 120px;
    height: 40px;
  }

  .copyrights .first-half-one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .content-one div div p:nth-of-type(2) {
    display: none;
  }

  .first-half .discription-footer {
    color: #999;
    width: 100%;
    margin-top: 17px;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .disclaimer-footer {
    color: #999;
    width: 100%;
    margin-top: 17px;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .first-half .social-icon {
    width: 156px !important;
    height: 25px !important;
  }

  .footer .headings {
    font-size: 16px;
  }

  .footer .content {
    font-size: 14px;
  }
  .column-one .first-half {
    align-items: left !important;
  }
  .footer .column-two {
    width: 227px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }

  .footer .column-three {
    width: 180px;
    gap: 0px;
  }

  .footer .column-four {
    /* width: 300px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }

  .row-one .columns {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    width: 100% !important;
  }

  .footer .play-store {
    width: 130px;
    height: 38px;
  }

  .column-four .download {
    display: flex;
    flex-direction: column;
    gap: 9px;
  }

  .copyrights .content-one {
    color: #B3B3B3;
    font-size: 14px;
    width: 400px;
  }
  .ant-collapse-borderless {
    background-color: transparent !important;
    width: 100%;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding:0px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 16px;
  }
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0px;
  }
  .footer-collapse > .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text{
    color: white !important;
    height: 37px;
  }
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    color: white !important;
  }
}

@media (max-width: 430px) {
  .desktop-view {
    display: none !important;
  }

  .mobile-view {
    display: contents;
  }

  .footer .row-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 16px 5px 16px;
  }

  .footer .column-one {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 0px !important;
    width: 100% !important;
    align-items: center;
  }

  .first-half .logo-footer {
    width: 120px;
    height: 40px;
  }

  .first-half .discription-footer {
    color: #999;
    width: 280px !important;
    max-width: fit-content;
    margin-top: 17px;
    font-size: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
 .disclaimer-footer {
    color: #999;
    max-width: fit-content;
    margin-top: 17px;
    font-size: 10px;
    margin-bottom: 20px;
    text-align: center;
  }

  .column-one .social-icon {
    width: 156px !important;
    height: 24px !important;
  }

  .footer .headings {
    font-size: 16px;
  }

  .footer .content {
    font-size: 14px;
  }

  .footer .column-two {
    width: 227px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }

  .footer .column-three {
    width: 180px;
    gap: 0px;
  }

  .footer .column-four {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }

  .row-one .columns {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    width: 100% !important;
  }

  .footer .play-store {
    width: 130px;
    height: 38px;
  }

  .column-four .download {
    display: flex;
    flex-direction: row;
    gap: 9px;
  }

  .copyrights {
    padding: 30px 16px 5px 16px;
  }
  .copyrights .content-one p  strong{
    /* white-space: nowrap; */
    margin-right: 2px;
  }
  .copyrights .content-one p{
    text-align: left;
    font-size: 10px;

  }

  .copyrights .content-one {
    color: #B3B3B3;
    font-size: 10px;
    width: 100%;
    text-align: center;
    line-height: 28px;
    align-items: baseline;
    justify-content: flex-start;
  }

  .copyrights .twenty-plus {
    width: 40px;
    height: 40px;
  }

  /* .ant-collapse-borderless>.ant-collapse-item {
    background-color: #fff;
  } */
  /* .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: rgb(255, 255, 255);
  } */

  .ant-collapse-borderless {
    width: 100%;
  }

  .ant-collapse-header {
    color: white
  }
  /* Ensuring the panel header text color only for specific-collapse */
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text{
  color: white !important;
  height: 32px;
}
.footer-collapse > .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text{
  padding: 16px;
}
  /* .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    color: white;
  } */

  .ant-collapse-borderless>.ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse {
    background-color: transparent;
  }
}

.footer .group {
  background-image: url();
  background-size: 100% 100%;
  height: 65px;
  left: 100px;
  position: absolute;
  top: 88px;
  width: 270px;
}

.footer .mask-group {
  height: 64.57px;
  left: 70px;
  position: absolute;
  top: 0;
  width: 270px;
}

.footer .text-wrapper {
  color: var(--gray-400);
  font-family: var(--display-font-12px-medium-font-family);
  font-size: var(--display-font-12px-medium-font-size);
  font-style: var(--display-font-12px-medium-font-style);
  font-weight: var(--display-font-12px-medium-font-weight);
  left: 100px;
  letter-spacing: var(--display-font-12px-medium-letter-spacing);
  line-height: var(--display-font-12px-medium-line-height);
  position: absolute;
  top: 176px;
  width: 327px;
}

.footer .frame {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  left: 693px;
  position: absolute;
  top: 88px;
  width: 1157px;
}

@media (max-width: 1620px) {
  .footer .frame {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    left: 430px;
    position: absolute;
    top: 50px;
    width: 1157px;
  }

  .footer .image-2 {
    left: 1500px !important;
  }

  .footer .product {
    width: 110px !important;
  }
}

.footer .product {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 227px;
}

.footer .div {
  color: var(--white);
  font-family: var(--display-font-24px-medium-font-family);
  font-size: var(--display-font-24px-medium-font-size);
  font-style: var(--display-font-24px-medium-font-style);
  font-weight: var(--display-font-24px-medium-font-weight);
  letter-spacing: var(--display-font-24px-medium-letter-spacing);
  line-height: var(--display-font-24px-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.footer .text-wrapper-2 {
  color: #b3b3b3;
  font-family: "Plus Jakarta Sans-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.36px;
  line-height: 27px;
  margin-top: -1px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.footer .text-wrapper-3 {
  color: #b3b3b3;
  font-family: "Plus Jakarta Sans-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.36px;
  line-height: 27px;
  position: relative;
  text-align: justify;
  white-space: nowrap;
  width: fit-content;
}

.footer .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.footer .p {
  color: #b3b3b3;
  font-family: var(--display-font-16px-regular-font-family);
  font-size: var(--display-font-16px-regular-font-size);
  font-style: var(--display-font-16px-regular-font-style);
  font-weight: var(--display-font-16px-regular-font-weight);
  height: 46px;
  letter-spacing: var(--display-font-16px-regular-letter-spacing);
  line-height: var(--display-font-16px-regular-line-height);
  margin-top: -1px;
  position: relative;
  text-align: justify;
  width: 186px;
}

.footer .text-wrapper-4 {
  color: #b3b3b3;
  font-family: var(--display-font-16px-regular-font-family);
  font-size: var(--display-font-16px-regular-font-size);
  font-style: var(--display-font-16px-regular-font-style);
  font-weight: var(--display-font-16px-regular-font-weight);
  letter-spacing: var(--display-font-16px-regular-letter-spacing);
  line-height: var(--display-font-16px-regular-line-height);
  position: relative;
  text-align: justify;
  width: 181px;
}

.footer .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.footer .text-wrapper-5 {
  color: #b3b3b3;
  font-family: var(--display-font-16px-regular-font-family);
  font-size: var(--display-font-16px-regular-font-size);
  font-style: var(--display-font-16px-regular-font-style);
  font-weight: var(--display-font-16px-regular-font-weight);
  letter-spacing: var(--display-font-16px-regular-letter-spacing);
  line-height: var(--display-font-16px-regular-line-height);
  position: relative;
  text-align: justify;
  width: 257px;
}

.footer .image {
  height: 68px;
  object-fit: cover;
  position: relative;
  width: 228px;
}

.footer .img {
  height: 68px;
  position: relative;
  width: 228px;
}

.footer .image-2 {
  height: 58px;
  left: 1790px;
  position: absolute;
  top: 510px;
  width: 60px;
}

.footer .frame-5 {
  height: 32px;
  left: 100px;
  position: absolute;
  top: 313px;
  width: 224px;
}

.footer .line {
  height: 1px;
  left: 70px;
  object-fit: cover;
  position: absolute;
  top: 493px;
  width: 1780px;
}

.footer .heading-MENU {
  color: var(--white);
  font-family: var(--display-font-16px-medium-font-family);
  font-size: var(--display-font-16px-medium-font-size);
  font-style: var(--display-font-16px-medium-font-style);
  font-weight: var(--display-font-16px-medium-font-weight);
  height: 24px;
  left: 70px;
  letter-spacing: var(--display-font-16px-medium-letter-spacing);
  line-height: var(--display-font-16px-medium-line-height);
  opacity: 0.5;
  position: absolute;
  top: 526px;
  white-space: nowrap;
}

.new-line {
  width: -webkit-fill-available;
  border-top: 1px solid var(--gray-200);
}
